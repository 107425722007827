import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  User,
} from "firebase/auth";
import { firebaseOptions } from "./config";

const app = initializeApp(firebaseOptions);
const analytics = getAnalytics(app);
const auth = getAuth(app);

onAuthStateChanged(auth, async (user) => {
  if (user) {
    // user is signed in
    logEvent(analytics, "app_start");
    startApp({ user });
  } else {
    // user is signed out
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // try to find email
      let email: string | null = null;
      try {
        email = window.localStorage.getItem("emailForSignIn");
      } catch (error) {
        // ignore (just for convenience)
      }
      // let user enter email if not found
      if (!email) {
        email = window.prompt(
          "Please provide your email for confirmation"
        ) as string;
      }
      // try to sign in
      try {
        await signInWithEmailLink(auth, email, window.location.href);
      } catch (error) {
        // handle errors
        switch (error.code) {
          case "auth/invalid-action-code":
            alert(
              "This sign-in link is invalid or has expired. Please sign in again!"
            );
            redirectToLoginPage();
            break;
          default:
            alert(
              "An error occured (${error.code}). Please contact support@thanku.business"
            );
        }
        return;
      }
      // try to clear email from storage
      try {
        window.localStorage.removeItem("emailForSignIn");
      } catch (error) {
        // ignore
      }
      // remove query params and hash from URL
      const url = new URL(location.href);
      window.history.replaceState(null, "", url.pathname);
    } else {
      // redirect to login page
      redirectToLoginPage();
    }
  }
});

function redirectToLoginPage(): void {
  window.location.href = "/login/";
}

function fail(error: Error): void {
  console.error(error);
  alert(`An error occured: ${error}`);
}

function startApp({ user }: { user: User }): void {
  document.body.innerHTML = `
    <header class="flex justify-center p-10 relative z-20">
      <img src="/thanku-business-logo.svg" alt="Logo" width="130" />
    </header>
    <main class="absolute inset-0 flex justify-center items-center flex-col z-10 p-10 text-center">
      <h1 class="font-bold text-2xl mt-4">Welcome ${user.email}!</h1>
      <p class="mt-6 max-w-lg">
        Here you'll be able to register as a business in the future and create ThankUs easily one by one, as a batch or a voucher, see statistics, browse all your ThankUs and a lot of other exciting stuff.<br/><br/><span class="text-x-orange-base animate-pulse">Stay tuned! We'll notify you by email.</span>
      </p>
      <p class="mt-6">
        <button class="border-2 border-x-orange-base hover:border-x-orange-base-emphasis py-2 px-6 rounded-md bg-x-orange-base hover:bg-x-orange-base-emphasis font-bold text-x-white disabled:bg-x-gray-base disabled:border-x-gray-base transition-colors" id="logoutButton">Logout</button>
      </p>
    </main>
  `;
  document.getElementById("logoutButton")?.addEventListener("click", () => {
    signOut(auth).then(() => {
      console.log("Successfully signed out");
    }, fail);
  });
}
